* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Public Sans";
}

.logo > img {
  object-fit: contain !important;
}

.formControlBox fieldset,
.userBox fieldset {
  border: none;
}

.userBox > div > label {
  margin: 0;
  align-items: flex-start;
}

.userBox > div > label > span:first-child {
  padding: 0;
  margin-right: 16px;
  padding-top: 3px;
}

.formControlBox label {
  background: white;
  top: -5px;
}

.formControlBox .MuiSelect-select,
.inputBox input {
  padding: 10px;
}

.recaptchaBox > div > div {
  margin: 0 auto;
}
